var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.mapWrap},[_c('div',{class:_vm.$style.boxMapwrap,style:({
      width: _vm.bigScreen ? '100vw' : '820px',
      height: _vm.bigScreen ? '100vh' : '500px',
      position: _vm.bigScreen ? 'fixed' : '',
      top: '-10px',
      left: 0,
      zIndex: 9999,
    })},[_c('div',{class:_vm.$style.fance,style:({ top: _vm.bigScreen ? '20px' : '10px' })},[_c('fence-select',{staticStyle:{"width":"150px","background-color":"#ffff"},on:{"change":_vm.handleSelectFence},model:{value:(_vm.searchForm.fence),callback:function ($$v) {_vm.$set(_vm.searchForm, "fence", $$v)},expression:"searchForm.fence"}})],1),_c('div',{class:_vm.$style.screen,staticStyle:{"z-index":"9999999"}},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":_vm.bigScreen ? 'tc-icon-fullscreen-exit' : 'tc-icon-fullscreen'},on:{"click":_vm.handleScreen}})],1),_c('div',{class:_vm.$style.boxMap,style:({
        width: _vm.bigScreen ? '100vw' : '820px',
        height: _vm.bigScreen ? '100vh' : '500px',
      }),attrs:{"id":"boxMap"}})]),_c('div',{class:_vm.$style.footer},[(!_vm.bigScreen)?_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('enterpriseManagement.camera.detail.closetxt'))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }