<template>
  <div :class="$style.mapWrap">
    <div
      :class="$style.boxMapwrap"
      :style="{
        width: bigScreen ? '100vw' : '820px',
        height: bigScreen ? '100vh' : '500px',
        position: bigScreen ? 'fixed' : '',
        top: '-10px',
        left: 0,
        zIndex: 9999,
      }"
    >
      <div :class="$style.fance" :style="{ top: bigScreen ? '20px' : '10px' }">
        <fence-select
          v-model="searchForm.fence"
          style="width: 150px; background-color: #ffff"
          @change="handleSelectFence"
        />
      </div>
      <div :class="$style.screen" style="z-index: 9999999">
        <x-icon
          :type="bigScreen ? 'tc-icon-fullscreen-exit' : 'tc-icon-fullscreen'"
          :class="$style.icon"
          @click="handleScreen"
        />
      </div>
      <div
        :class="$style.boxMap"
        id="boxMap"
        :style="{
          width: bigScreen ? '100vw' : '820px',
          height: bigScreen ? '100vh' : '500px',
        }"
      ></div>
    </div>

    <div :class="$style.footer">
      <a-button
        v-if="!bigScreen"
        size="small"
        type="primary"
        @click="handleClose"
      >
        {{ $t('enterpriseManagement.camera.detail.closetxt') }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  mouseEvent,
  funAMap,
} from '@/views/hat/electron-fence/components/util';
import FenceSelect from '@/views/hat/components/fence-select/fence-select.vue';
import AudioIcon from '!!svg-inline-loader!./screen-map/map-icon/audio-icon.svg';
import PlayIcon from '!!svg-inline-loader!./screen-map/map-icon/play-icon.svg';
import FileIcon from '!!svg-inline-loader!./screen-map/map-icon/file-icon.svg';
import { UploadService } from '@triascloud/services';
import { delay } from '@triascloud/utils';

let map, AMap;
@Component({
  components: {
    FenceSelect,
  },
})
export default class TimeLineMap extends Vue {
  @Prop({ type: Array, default: () => [] }) fileList;
  @Prop({ type: Number, default: 0 }) total;

  bigScreen = false;
  disabled = true;
  searchForm = {
    fence: undefined,
  };
  circleMarker = [];
  handleSelectFence(id) {
    this.deleteCircle();
    id && this.handleFenceChange(id);
  }
  handleScreen() {
    this.bigScreen = !this.bigScreen;
  }
  handleClose() {
    this.$listeners.handleClose();
  }
  async mounted() {
    this.initMap();
    this.loadFilePosition();
  }
  async initMap() {
    let { lng, lat } = this.fileList[0];
    AMap = await funAMap({
      plugins: ['AMap.PolygonEditor', 'AMap.CircleEditor', 'AMap.PlaceSearch'],
    });
    const { mode } = this.$store.state.crossStorage.skin;
    map = new AMap.Map('boxMap', {
      zoom: 17,
      center: [lng, lat],
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
  }

  async loadFilePosition() {
    const array = await getZonesData({
      geofenceId: this.fileList[0].geofenceId,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, AMap, (a, b) =>
      mouseEvent(a, b, map),
    );
    list.forEach(item => {
      this.circleMarker.push(item);
    });
    map.add(list);
    let fileList = JSON.parse(JSON.stringify(this.fileList));
    fileList.forEach(async item => {
      item.path = await this.getFileUrl(item);
      item.positionList = [`${item.lng},${item.lat}`];
    });
    await delay(1000);
    await this.createPositionMakers(fileList);
  }

  async getFileUrl(obj) {
    let path = obj.path;
    if (obj.type === 'VIDEO') {
      path =
        path + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_50,h_50,m_fast';
    } else if (obj.type === 'PHOTO') {
      path = path + '?x-oss-process=image/resize,h_50,m_lfit';
    }
    const customService = new UploadService('/oss/iot/oss');
    const abPath = await customService.getAuth(path);
    return abPath;
  }

  createPositionMakers(fileList) {
    let positions = fileList.map(item => {
      let dom = '';
      // item.total = item.total > 99 ? '99+' : item.total;
      const label = `<div class="label ${item.total ? '' : 'label__hidden'}">${
        item.total
      }</div>`;
      switch (item.type) {
        case 'PHOTO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            <img src="${item.path}" />
            ${label}
          </div>`;
          break;
        case 'VIDEO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            <div class="video-container">
              ${PlayIcon}
              <img src="${item.path}" />
            </div>
            ${label}
          </div>`;
          break;
        case 'AUDIO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            ${AudioIcon}
            ${label}
          </div>`;
          break;
        default:
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            ${FileIcon}
            ${label}
          </div>`;
      }
      return {
        position: item.positionList[0].split(','),
        dom,
        country: item.country,
        province: item.province,
        city: item.city,
        district: item.district,
        township: item.township,
        address: item.address,
      };
    });
    positions.forEach(item => {
      new AMap.Marker({
        map: map,
        content: item.dom,
        position: [+item.position[0], +item.position[1]],
        offset: new AMap.Pixel(-13, -30),
      });
    });
  }
  handleDisabledChange(disabled) {
    disabled && this.deleteCircle();
    !disabled && this.addCircle();
    this.disabled = disabled;
  }
  deleteCircle() {
    this.circleMarker.forEach(item => {
      map.remove(item);
    });
  }
  addCircle() {
    this.circleMarker.forEach(item => {
      item.setMap(map);
    });
  }
  async handleFenceChange(id) {
    const array = await getZonesData({
      geofenceId: id,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, AMap, (a, b) =>
      mouseEvent(a, b, map),
    );
    list.forEach(item => {
      this.circleMarker.push(item);
    });
    map.add(list);
    if (array[0]?.zone[0]) {
      map.setCenter([array[0]?.zone[0].point.lng, array[0]?.zone[0].point.lat]);
    } else {
      map.setCenter([array[0]?.zone[1][0].lng, array[0]?.zone[1][0].lat]);
    }
  }
}
</script>
<style lang="less" module>
:global .ant-select-dropdown {
  z-index: 9999999;
}
.boxMapwrap {
  position: relative;
  .fance {
    position: absolute;
    right: 10px;
    z-index: 9999;
  }
  .screen {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }
}
.mapWrap {
  .footer {
    margin-top: 10px;
    display: flex;
    height: 40px;
    flex-direction: row-reverse;
    .tip {
      margin-left: 20px;
    }
  }
}
:global {
  .custom-content-marker {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #cdcdcd;
    border-radius: 6px;
    &.active {
      border: 2px solid var(--font-active);
    }
    img {
      width: 88%;
      height: 88%;
      border-radius: 6px;
      object-fit: cover;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .video-container {
      width: 88%;
      height: 88%;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      & > img {
        height: 100%;
        width: 100%;
      }
      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        color: #fff;
      }
    }
    .label {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      min-width: 20px;
      padding: 0 2px;
      font-size: 12px;
      background: var(--primary);
      border-radius: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
    .label__hidden {
      display: none;
    }
  }
}
</style>
