var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.manageBox},[_c('layout-content',{scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('a-radio-group',{on:{"change":_vm.handleTabChange},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('a-radio-button',{attrs:{"value":_vm.TABS_KEYS.ALL_FILE}},[_vm._v(" 全部文件 ")]),_c('a-radio-button',{attrs:{"value":_vm.TABS_KEYS.FILE_MAP_MODE}},[_vm._v(" 地图模式 ")]),_c('a-radio-button',{attrs:{"value":_vm.TABS_KEYS.TIME_LINE}},[_vm._v(" 时间线 ")])],1)]},proxy:true},{key:"header-right",fn:function(){return [(
          _vm.activeKey === _vm.TABS_KEYS.ALL_FILE &&
            _vm.$p.action('IMAGE_LABELS', '/hat/file')
        )?_c('a-button',{class:_vm.$style.btnSm,attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleCallout}},[_vm._v("标签管理")]):_vm._e()]},proxy:true}])},[_c('div',{class:_vm.$style.wrapperContent,attrs:{"slot":"content"},slot:"content"},[_c('div',{class:_vm.$style.topWrap},[_c('div',{class:_vm.$style.formWrap,style:(_vm.isFull
              ? {
                  position: 'fixed',
                  right: '70px',
                  top: '10px',
                  zIndex: 102,
                }
              : '')},[_c('a-range-picker',{style:(_vm.isFull
                ? {
                    width: '300px',
                    background: _vm.selectInputBackground,
                  }
                : {
                    width: '300px',
                  }),attrs:{"show-time":{ format: 'YYYY-MM-DD HH:mm:ss' },"ranges":_vm.presetTime,"format":"YYYY-MM-DD HH:mm:ss","allowClear":true,"placeholder":[
              _vm.$t('hat.clockRecord.startDate'),
              _vm.$t('hat.clockRecord.endDate') ]},model:{value:(_vm.search.dateRange),callback:function ($$v) {_vm.$set(_vm.search, "dateRange", $$v)},expression:"search.dateRange"}}),_c('a-select',{style:(_vm.isFull
                ? {
                    marginLeft: '10px',
                    background: _vm.selectInputBackground,
                  }
                : {
                    marginLeft: '10px',
                  }),attrs:{"placeholder":"全部类型"},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.key}},[_vm._v(_vm._s(item.label))])}),1),_c('a-select',{style:(_vm.isFull
                ? {
                    width: '200px',
                    marginLeft: '10px',
                    background: _vm.selectInputBackground,
                  }
                : {
                    width: '200px',
                    marginLeft: '10px',
                  }),attrs:{"show-search":"","placeholder":_vm.$t('hat.managementModal.event.project'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null},on:{"search":_vm.handleProjectSearch,"change":_vm.handleProjectChange},model:{value:(_vm.search.projectId),callback:function ($$v) {_vm.$set(_vm.search, "projectId", $$v)},expression:"search.projectId"}},_vm._l((_vm.projects),function(item){return _c('a-select-option',{key:item.pkId,attrs:{"value":item.pkId}},[_vm._v(_vm._s(item.pmName))])}),1),_c('a-select',{style:(_vm.isFull
                ? {
                    width: '200px',
                    marginLeft: '10px',
                    background: _vm.selectInputBackground,
                  }
                : {
                    width: '200px',
                    marginLeft: '10px',
                  }),attrs:{"show-search":"","placeholder":_vm.$t('hat.clockRecord.allGroups'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null},on:{"search":_vm.handleGroupSearch},model:{value:(_vm.search.groupId),callback:function ($$v) {_vm.$set(_vm.search, "groupId", $$v)},expression:"search.groupId"}},_vm._l((_vm.groups),function(item){return _c('a-select-option',{key:item.pkId,attrs:{"value":item.pkId}},[_vm._v(_vm._s(item.deviceGroupName))])}),1),_c('a-input',{style:(_vm.isFull
                ? {
                    width: '180px',
                    margin: '0 10px',
                    background: _vm.selectInputBackground,
                  }
                : {
                    width: '180px',
                    margin: '0 10px',
                  }),attrs:{"placeholder":"设备号/姓名/地址/标签"},model:{value:(_vm.search.content),callback:function ($$v) {_vm.$set(_vm.search, "content", $$v)},expression:"search.content"}}),_c('a-button',{class:_vm.$style.btnOther,attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("搜索")]),_c('a-button',{class:_vm.$style.btnOther,style:(_vm.isFull
                ? {
                    background: _vm.selectInputBackground,
                  }
                : ''),on:{"click":_vm.handleReset}},[_vm._v("清空")]),(_vm.activeKey === _vm.TABS_KEYS.ALL_FILE)?_c('a-button',{class:_vm.$style.btnOther,attrs:{"type":"primary"},on:{"click":function () { return _vm.handleDownload(); }}},[_vm._v("导出")]):_vm._e()],1)]),(_vm.activeKey === _vm.TABS_KEYS.ALL_FILE)?_c('FileTable',{attrs:{"params":_vm.search}}):_vm._e(),(_vm.activeKey === _vm.TABS_KEYS.FILE_MAP_MODE)?_c('FileScreenMap',{attrs:{"params":_vm.search},on:{"handleFullScreen":_vm.handleFullScreen}}):_vm._e(),(_vm.activeKey === _vm.TABS_KEYS.TIME_LINE)?_c('TimeLine',{attrs:{"params":_vm.search}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }