<template>
  <x-scroll-view
    :class="$style.container"
    :disabled="isEnd"
    :lowerThreshold="80"
    :immediateCheck="false"
    ref="Ref"
    @scrolltolower="loadMoreFiles"
  >
    <a-timeline>
      <a-timeline-item
        v-for="(item, index) in fileList"
        :key="index"
        :ref="'timeline' + index"
      >
        <div :class="$style.time">
          {{ $moment(item.fileDay).format('YYYY年MM月DD') }}
        </div>
        <div :class="$style.name" @click="handleOpenMap(item)">
          {{ item.address }}
        </div>
        <div :class="$style.fileList">
          <template>
            <a-tooltip
              placement="bottom"
              v-for="fileItem in item.pathList.fileList"
              :key="fileItem.pkId"
              :mouseEnterDelay="1"
            >
              <div :class="$style.fileItem" @click="handleShowFile(fileItem)">
                <x-oss-image
                  :class="$style.thumbnail"
                  basePath="/oss/iot/oss"
                  v-if="fileItem.type === 'PHOTO'"
                  :ossPath="
                    fileItem.path + '?x-oss-process=image/resize,h_100,m_lfit'
                  "
                />
                <video-thumbnail
                  :class="$style.thumbnail"
                  basePath="/oss/iot/oss"
                  :ossPath="fileItem.path"
                  size="100"
                  :hasClick="false"
                  v-else-if="fileItem.type === 'VIDEO'"
                />
                <div :class="$style.iconBox" v-else>
                  <x-icon
                    :type="FILE_ICON[fileItem.type]"
                    :class="$style.icon"
                  />
                </div>
              </div>
              <div
                slot="title"
                style="cursor: pointer"
                @click="openManageModal(fileItem.idxDeviceId)"
              >
                <div>
                  创建：{{
                    `${fileItem.userName}${
                      fileItem.roles.length
                        ? `（${fileItem.roles[0].roleName}）`
                        : ''
                    }`
                  }}
                </div>
                <div>设备：{{ fileItem.deviceName }}</div>
                <div>
                  创建时间：{{
                    fileItem.fileTime
                      ? $moment(fileItem.fileTime).format('YYYY-MM-DD HH:mm:ss')
                      : ''
                  }}
                </div>
              </div>
            </a-tooltip>
          </template>
        </div>
        <div
          :class="$style.loadMore"
          @click="handleLoadMore(item)"
          v-if="item.pathList.hasNext"
        >
          加载更多
        </div>
      </a-timeline-item>
    </a-timeline>
  </x-scroll-view>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { fileMapDetail, moreFileMapDetail } from '@/services/smart-hat/file';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import { createModal } from '@triascloud/x-components';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import Preview from '@/views/hat/file/preview/index.vue';
import { FILE_SEARCH_EVENT_EMIT } from '@/views/hat/file/index.vue';
import { Emitter } from '@/utils';
import TimeLineMap from './timeline-map.vue';

@Component({
  components: {
    VideoThumbnail,
  },
})
export default class TimeLine extends Vue {
  mounted() {
    this.getFileList();
    Emitter.$on(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
  }
  beforeDestroy() {
    Emitter.$off(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
  }

  handleSearch(type, obj) {
    this.searchParams.content = obj.content;
    this.searchParams.groupId = obj.groupId;
    this.searchParams.projectId = obj.projectId;
    this.searchParams.type = obj.type;
    this.searchParams.startTime = obj.dateRange[0]
      ? this.$moment(obj.dateRange[0]).valueOf()
      : '';
    this.searchParams.endTime = obj.dateRange[1]
      ? this.$moment(obj.dateRange[1]).valueOf()
      : '';
    this.getFileList();
  }
  FILE_ICON = {
    PHOTO: 'tc-color-file-picture',
    VIDEO: 'tc-color-file-video',
    AUDIO: 'tc-color-file-voice',
    OTHER: 'tc-color-file-unknown',
  };
  fileList = [];
  pagination = {
    current: 1,
    size: 10,
  };
  searchParams = {};
  async getFileList() {
    const params = {
      ...this.pagination,
      ...this.searchParams,
    };
    const { records } = await fileMapDetail(params);
    this.fileList = records;
    // 判断下一页是否还有数据
    this.isEnd = records.length < this.pagination.size;
  }
  handleShowFile(fileItem) {
    Preview.createModal({
      urls: fileItem.path,
      opts: [
        {
          id: fileItem.pkId,
          relatedFileList: fileItem.relatedFilePathList,
          videoTrackModel: fileItem.videoTrackModel,
          videoTrackModelWatermark: fileItem.videoTrackModelWatermark,
        },
      ],
      ossBasePath: '/oss/iot/oss',
    });
  }
  isEnd = false;
  async loadMoreFiles() {
    this.pagination.current += 1;
    const params = {
      ...this.pagination,
      ...this.searchParams,
    };
    const { records } = await fileMapDetail(params);
    this.fileList = [...this.fileList, ...records];
    this.isEnd = records.length < this.pagination.size;
  }
  async handleLoadMore(record) {
    record.current = record.current ? record.current : 1;
    record.current++;
    const fileList = record.pathList.fileList;
    const params = {
      address: record.address,
      lastPkId: fileList[fileList.length - 1].pkId,
      startTime: record.fileDay,
      endTime: record.fileDay + 86399000,
      content: this.searchParams.content,
      current: record.current,
      size: 10,
      groupId: this.searchParams.groupId,
      projectId: this.searchParams.projectId,
      type: this.searchParams.type,
    };
    const data = await moreFileMapDetail(params);
    record.pathList.hasNext = data.hasNext;
    record.pathList.fileList = [...record.pathList.fileList, ...data.fileList];
  }
  handleOpenMap(record) {
    const modal = createModal(
      h =>
        h(TimeLineMap, {
          props: {
            fileList: record.pathList.fileList,
            total: record.total,
          },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        width: 860,
        title: '地理位置',
        maskClosable: false,
        className: this.$style.ManageModel,
      },
    );
  }
  openManageModal(id) {
    if (!id) {
      this.$message.error('设备不存在！');
    }
    if (this.$p.action('READ', '/hat/device')) {
      createModal(() => <ManageModel record={{ deviceId: id }} />, {
        width: 1280,
        title: this.$t('hat.deviceManagement.manage'),
        maskClosable: false,
        className: this.$style.ManageModel,
      });
    }
  }
}
</script>
<style lang="less" module>
.container {
  padding: 10px;
  .time {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
  }
  .name {
    font-size: 16px;
    color: var(--primary);
    text-decoration-line: underline;
    cursor: pointer;
  }
  .fileList {
    margin: 4px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .fileItem {
      .thumbnail {
        height: 100px;
        width: 100px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 8px;
      }
      .iconBox {
        height: 100px;
        width: 100px;
        border-radius: 8px;
        background: var(--block-bg);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px 0px #e1e1e1;
        .icon {
          font-size: 75px;
        }
      }
    }
  }
  .loadMore {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
